export default [
  // weapons
  {
    damage: 7,
    effect: 'damage',
    icon: 'axe',
    name: 'hunting axe',
    price: 9,
    set: 3,
    size: '15px',
  },
  {
    damage: 8,
    effect: 'damage',
    element: 'ice',
    icon: 'hammer',
    name: 'icy blow',
    price: 10,
    set: 3,
  },
  {
    damage: 10,
    effect: 'damage',
    element: 'electric',
    icon: 'bow',
    name: 'storm bringer',
    price: 11,
    set: 3,
    size: '13px',
  },

  // magic
  {
    cost: 7,
    damage: 9,
    effect: 'damage',
    element: 'ice',
    icon: 'magic',
    name: 'frost ray',
    price: 8,
    set: 3,
  },
  {
    cost: 7,
    damage: 9,
    effect: 'damage',
    element: 'poison',
    icon: 'magic',
    name: 'venom',
    price: 8,
    set: 3,
  },
  {
    cost: 8,
    damage: 10,
    effect: 'damage',
    element: 'water',
    icon: 'magic',
    name: 'tsunami',
    price: 9,
    set: 3,
  },

  // armor
  {
    armor: 8,
    effect: 'armor',
    icon: 'gloves',
    name: 'iron gloves',
    price: 8,
    set: 3,
  },
  {
    armor: 9,
    effect: 'armor',
    icon: 'helm',
    name: 'iron helm',
    price: 9,
    set: 3,
  },
  {
    armor: 10,
    effect: 'armor',
    icon: 'breastplate',
    name: 'iron chest',
    price: 10,
    set: 3,
  },

  // items
  {
    effect: 'heal',
    health: 7,
    icon: 'potion',
    name: 'hp potion iv',
    price: 9,
    set: 3,
    size: '14px',
  },
  {
    effect: 'mana',
    icon: 'potion',
    mana: 7,
    name: 'mp potion iv',
    price: 9,
    set: 3,
    size: '14px',
  },
];
