export default [
  // weapons
  {
    damage: 3,
    effect: 'damage',
    icon: 'axe',
    name: 'axe',
    price: 3,
    set: 1,
  },
  {
    damage: 4,
    effect: 'damage',
    icon: 'hammer',
    name: 'hammer',
    price: 4,
    set: 1,
  },
  {
    damage: 5,
    effect: 'damage',
    icon: 'bow',
    name: 'long bow',
    price: 5,
    set: 1,
  },

  // magic
  {
    cost: 4,
    duration: 2,
    effect: 'freeze',
    element: 'ice',
    icon: 'magic',
    keyword: 'freeze',
    name: 'cold snap',
    price: 4,
    set: 1,
  },
  {
    cost: 4,
    duration: 2,
    effect: 'poison',
    element: 'poison',
    icon: 'magic',
    keyword: 'poison',
    name: 'smog',
    price: 4,
    set: 1,
  },
  {
    cost: 4,
    damage: 5,
    effect: 'damage',
    element: 'electric',
    icon: 'magic',
    name: 'lightning bolt',
    price: 4,
    set: 1,
    size: '12px',
  },

  // armor
  {
    armor: 4,
    effect: 'armor',
    icon: 'gloves',
    name: 'brass gloves',
    price: 4,
    set: 1,
    size: '14px',
  },
  {
    armor: 5,
    effect: 'armor',
    icon: 'helm',
    name: 'brass helm',
    price: 5,
    set: 1,
  },
  {
    armor: 6,
    effect: 'armor',
    icon: 'breastplate',
    name: 'brass chest',
    price: 6,
    set: 1,
  },

  // items
  {
    effect: 'heal',
    health: 5,
    icon: 'potion',
    name: 'hp potion ii',
    price: 3,
    set: 1,
    size: '14px',
  },
  {
    effect: 'mana',
    icon: 'potion',
    mana: 5,
    name: 'mp potion ii',
    price: 4,
    set: 1,
    size: '14px',
  },
  {
    effect: 'cure',
    icon: 'crystal',
    keyword: 'cure',
    name: 'cure stone',
    price: 5,
    set: 1,
  },
];
