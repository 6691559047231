export default [
  'The Labyrinth of Sinister Malice',
  'The Lost Caverns of the Lich Baroness',
  'The Delve of Byzu',
  'The Forsaken Tomb of the Demon Empress',
  'The Secret Shrine of the Vampire Duchess',
  'The Secret Sepulcher of Evil',
  'The Temple of Fiendish Madness',
  'The Vaults of Damned Terror',
  'The Warrens of Horrendous Secrets',
  'The Halls of Accursed Secrets',
  'The Barrow of the Wraith Princess',
  'The Lair of Lama the Necromancer',
  'The Black Tunnels of Vala the Possessed',
  'The Delve of Thimunda the Indomitable',
  'The Delve of Horrendous Malice',
  'The Secret Barrow of Evil',
  'The Lost Chambers of Maltha',
  'The Tomb of Ghastly Necromancy',
  'The Gauntlet of Infernal Necromancy',
  'The Secret Warrens of Nightmares',
  'The Sepulcher of the Wraith Baron',
  'The Forsaken Hive of Amaz',
  'The Barrow of Demonic Ages',
  'The Lost Labyrinth of Mala the Gruesome',
  'The Forsaken Sanctum of the Gargoyle Tyrant',
  'The Lost Vaults of Secrets',
  'The Dark Vaults of the Shadow Empress',
  'The Barrow of Ghastly Evil',
  'The Dread Temple of Ruin',
  'The Delve of the Vampire Empress',
  'The Sanctum of Kas the Bloody',
  'The Dark Hive of Worms',
  'The Vaults of Demonic Worms',
  'The Sepulcher of Unearthly Sorrows',
  'The Dark Lair of Marbelph',
  'The Forsaken Gauntlet of Abyn the Profane',
  'The Secret Delve of Sorrows',
  'The Black Dungeon of Madness',
  'The Barrow of Saraha',
  'The Vaults of Lord Greywulf',
  'The Lost Tunnels of the Demon Count',
  'The Secret Catacombs of Secrets',
  'The Lost Lair of the Vampire Baroness',
  'The Barrow of Illfang the Kobold Lord',
  'The Black Undercrypt of the Demon Tyrant',
  'The Lost Vaults of the Wyrm Duchess',
  'The Dark Chambers of Sorrows',
  'The Lost Tomb of Horror',
  'The Shrine of Ammos',
  'The Temple of Barbos the Ghastly',
  'The Lost Warrens of Woe',
  'The Forsaken Caverns of Chaos',
  'The Forsaken Prison of the Wraith Princess',
  'The Forsaken Dungeon of Chaos',
  'The Dark Labyrinth of Woe',
  'The Shrine of the Lich Duchess',
  'The Secret Crypts of Worms',
  'The Lost Pit of Illfang the Kobold Lord',
  'The Lost Chambers of Ariger the Baleful',
  'The Vaults of Damned Madness',
  'The Catacombs of Illfang the Kobold Lord',
  'The Dread Tunnels of Woe',
  'The Forsaken Lair of the Wyrm Count',
  'The Lost Shrine of the Shadow Tyrant',
  'The Lost Catacombs of Akvan the Awesome',
  'The Dark Catacombs of Chaos',
  'The Hive of the Vampire Prince',
  'The Black Vaults of Woe',
  'The Black Catacombs of Malice',
  'The Lost Gauntlet of Harida',
  'The Crypts of Asyan the Lunatic',
  'The Secret Caverns of Nabal the Sorceror',
  'The Lost Lair of the Lich Countess',
  'The Dark Tunnels of the Vampire Prince',
  'The Dark Barrow of Sorrows',
  'The Dread Sanctum of Madness',
  'The Hive of Nexas',
  'The Dungeon of Grim Madness',
  'The Dread Pit of Evil',
  'The Warrens of Gothmog of Udun',
  'The Labyrinth of Azun',
  'The Black Lair of Ulfang the Black',
  'The Secret Temple of Zeiram the Lich',
  'The Dread Halls of Horror',
  'The Forsaken Pit of Sorrows',
  'The Black Tomb of Amus',
  'The Hive of Wicked Necromancy',
  'The Dungeon of Misamsu',
  'The Dungeon of Horrendous Devastation',
  'The Black Barrow of Nightmares',
  'The Cyst of Unearthly Ruin',
  'The Forsaken Vaults of Gilda the Merciless',
  'The Warrens of Malefic Devastation',
  'The Caverns of Agares',
  'The Shrine of Crimson Souls',
  'The Crypts of Kas the Bloody',
  'The Tunnels of Vale the Terrible',
  'The Black Halls of Necromancy',
  'The Lost Sanctum of Chaos',
  'The Caverns of Mighty Death',
  'The Forsaken Tomb of Secrets',
  'The Dread Sepulcher of Himarca',
  'The Dread Sepulcher of Lord Greywulf',
  'The Secret Sepulcher of Nightmares',
  'The Tunnels of Gothmog of Udun',
  'The Black Tomb of Malice',
  'The Catacombs of Tenebrous Worms',
  'The Hive of Mighty Chaos',
  'The Halls of Kobyz the Possessed',
  'The Secret Undercrypt of Annihilation',
  'The Black Prison of the Goblin King',
  'The Lost Dungeon of the Vampire Empress',
  'The Hive of Vali',
  'The Dread Undercrypt of Necromancy',
  'The Prison of Elemental Terror',
  'The Undercrypt of Awesome Ages',
  'The Dark Catacombs of Annilus',
  'The Dread Hive of Death',
  'The Sepulcher of Pryala',
  'The Lost Tunnels of Sindaga the Adamant',
  'The Dark Tomb of Kas the Bloody',
  'The Lost Temple of Geberta the Crimson',
  'The Black Tomb of Horror',
  'The Crypts of Arcane Terror',
  'The Secret Warrens of Ruin',
  'The Chambers of Basa',
  'The Secret Delve of Malice',
  'The Dungeon of Arcane Death',
  'The Forsaken Caverns of Horror',
  'The Warrens of Horrendous Horror',
  'The Dread Barrow of Zeiram the Lich',
  'The Barrow of the Lich Baroness',
  'The Crypts of Comiro the Possessed',
  'The Labyrinth of Bloody Souls',
  'The Crypts of Malefic Sorrows',
  'The Sepulcher of Sinister Woe',
  'The Vaults of Gruesome Woe',
  'The Dark Tomb of Myala',
  'The Dark Lair of Souls',
  'The Cyst of Aphotic Death',
  'The Cyst of Alphac',
  'The Lost Prison of Suuhadna',
  'The Temple of Black Devastation',
  'The Dread Undercrypt of Malice',
  'The Black Hive of the Lich Baroness',
  'The Undercrypt of Adamant Horror',
  'The Secret Tomb of Souls',
  'The Vaults of Arbach',
  'The Vaults of Vano the Bloody',
  'The Warrens of Emirkol the Chaotic',
  'The Sanctum of Indomitable Worms',
  'The Prison of Mali the Fiendish',
  'The Warrens of the Gargoyle Emperor',
  'The Catacombs of Fiendish Worms',
  'The Labyrinth of Fiendish Evil',
  'The Secret Sepulcher of the Wraith Duchess',
  'The Vaults of the Lich Count',
  'The Forsaken Undercrypt of Bumisu the Ghastly',
  'The Catacombs of Bloody Worms',
  'The Lost Caverns of Necromancy',
  'The Forsaken Prison of Ages',
  'The Hive of Ulfang the Black',
  'The Catacombs of Wambiga the Baatorian',
  'The Lair of Tenebrous Woe',
  'The Hive of the Wraith Prince',
  'The Dark Vaults of Lord Greywulf',
  'The Prison of Ulfang the Black',
  'The Black Vaults of Terror',
  'The Black Crypts of Gentigoia the Indomitable',
  'The Sanctum of Gusya',
  'The Shrine of the Vampire Baron',
  'The Secret Temple of Souls',
  'The Prison of Nabaalu',
  'The Dungeon of Sunless Annihilation',
  'The Halls of Ghastly Death',
  'The Tunnels of Asbarus the Malevolent',
  'The Hive of Gruesome Sorrows',
  'The Caverns of Ares',
  'The Secret Prison of Sorrows',
  'The Delve of Adamant Worms',
  'The Dark Sepulcher of the Lich Duke',
  'The Lost Caverns of Sorrows',
  'The Labyrinth of Accursed Nightmares',
  'The Secret Lair of Mary',
  'The Black Undercrypt of Malice',
  'The Sanctum of Gloomy Worms',
  'The Dark Caverns of Sorrows',
  'The Secret Pit of Zeiram the Lich',
  'The Undercrypt of Kas the Betrayer',
  'The Black Barrow of Chaos',
  'The Dark Barrow of Souls',
  'The Tomb of Emirkol the Chaotic',
  'The Dread Barrow of Madness',
  'The Labyrinth of Ghastly Evil',
  'The Dark Prison of Kas the Bloody',
  'The Sepulcher of Gothmog of Udun',
  'The Dread Shrine of Worms',
  'The Labyrinth of Fell Annihilation',
  'The Black Vaults of Malice',
  'The Black Barrow of Secrets',
];
