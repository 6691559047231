export default [
  {
    armor: 0,
    attack: 7,
    gold: 9,
    health: 7,
    level: 5,
    mana: 0,
    name: 'banshee',
  },
  {
    armor: 0,
    attack: 7,
    gold: 10,
    health: 8,
    level: 5,
    mana: 0,
    name: 'demogorgon',
    resistance: 'poison',
  },
  {
    armor: 0,
    attack: 8,
    gold: 11,
    health: 10,
    level: 5,
    mana: 0,
    name: 'troll',
    resistance: 'nature',
  },
  {
    armor: 0,
    attack: 10,
    gold: 11,
    health: 11,
    level: 5,
    mana: 0,
    name: 'wyvern',
    resistance: 'fire',
    specials: [
      {
        chance: 0.2,
        damage: 10,
        effect: 'damage',
        element: 'fire',
        message: {
          copy: 'Enemy %1 breathes fire scorching you for %2 damage',
          direction: 'outbound',
        },
      },
    ],
  },
];
