export default [
  'ailments',
  'armor',
  'burn',
  'burns',
  'electric',
  'hand',
  'ice',
  'fire',
  'freeze',
  'freezes',
  'frozen',
  'gold',
  'nature',
  'paralyse',
  'paralysed',
  'paralyses',
  'poison',
  'poisons',
  'water',
  'HP',
  'MP',
];
