export default [
  // weapons
  {
    damage: 12,
    effect: 'damage',
    element: 'nature',
    icon: 'sword',
    name: 'flora blade',
    price: 11,
    set: 4,
    size: '15px',
  },
  {
    damage: 12,
    effect: 'damage',
    element: 'electric',
    icon: 'sword',
    name: 'shocking sword',
    price: 11,
    set: 4,
    size: '12px',
  },
  {
    damage: 13,
    effect: 'damage',
    element: 'water',
    icon: 'bow',
    name: 'drowned bow',
    price: 12,
    set: 4,
    size: '15px',
  },

  // magic
  {
    cost: 8,
    duration: 5,
    effect: 'poison',
    element: 'poison',
    icon: 'magic',
    keyword: 'poison',
    name: 'death',
    price: 11,
    set: 4,
  },
  {
    cost: 8,
    duration: 5,
    effect: 'fire',
    element: 'fire',
    icon: 'magic',
    keyword: 'burn',
    name: 'inferno',
    price: 11,
    set: 4,
  },
  {
    cost: 8,
    duration: 5,
    effect: 'electric',
    element: 'electric',
    icon: 'magic',
    keyword: 'paralyse',
    name: 'thunder',
    price: 11,
    set: 4,
  },
  {
    cost: 8,
    duration: 5,
    effect: 'ice',
    element: 'ice',
    icon: 'magic',
    keyword: 'freeze',
    name: 'blizzard',
    price: 11,
    set: 4,
  },

  // armor
  {
    armor: 10,
    effect: 'armor',
    icon: 'gloves',
    name: 'mithral gloves',
    price: 9,
    set: 4,
    size: '12px',
  },
  {
    armor: 11,
    effect: 'armor',
    icon: 'helm',
    name: 'mithral helm',
    price: 10,
    set: 4,
    size: '14px',
  },
  {
    armor: 12,
    effect: 'armor',
    icon: 'breastplate',
    name: 'mithral chest',
    price: 11,
    set: 4,
    size: '13px',
  },

  // items
  {
    effect: 'heal',
    health: 12,
    icon: 'potion',
    name: 'hp potion v',
    price: 11,
    set: 4,
    size: '15px',
  },
  {
    effect: 'mana',
    icon: 'potion',
    mana: 12,
    name: 'mp potion v',
    price: 11,
    set: 4,
    size: '15px',
  },
];
