export default [
  {
    armor: 1,
    attack: 1,
    gold: 2,
    health: 3,
    mana: 0,
    name: 'dragon',
  },
];
